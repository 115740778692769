import React, { useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Title } from '../../../components/texts';
import { Radius } from '../../../styles/theme';
import { MaterialIcons } from '@expo/vector-icons';
import { ROUTES } from '../../../router/Routes';
import { useNavigation } from '@react-navigation/native';
import { NoActivityIndicator } from '../../../components/feedbacks/NoActivityIndicator';
import { Container } from '../../../layouts';
import { useUserContext } from '../../../providers/UserProvider';
import FreeBedModal from '../../../components/modals/FreeBedModal';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../providers/AuthProvider';
import { DefaultService } from '../../../generated/api';
import { Loader } from '../../../components/loader/Loader';

const Item = ({ title, occupedBy, id, setCurrentPosition, setModalVisible }: any) => {
  return (
    <TouchableOpacity
      onPress={() => {
        setCurrentPosition(id);
        setModalVisible(true);
      }}
    >
      <View style={styles.itemContainer}>
        <Container flexCol alignStart justifyStart>
          <Container alignCenter justifyStart flexRow>
            <Title h3 mvXS mhS>
              {title}
            </Title>
          </Container>
          <Container alignCenter justifyStart flexRow>
            <Title h3 primary mvXS mhS>
              {occupedBy}
            </Title>
          </Container>
        </Container>
        {/*<Container alignEnd flex={1}>
          <MaterialIcons name="logout" size={24} color="red" />
  </Container>*/}
      </View>
    </TouchableOpacity>
  );
};

const BedsOverview = () => {
  const authContext = useAuthContext();
  const userContext = useUserContext();
  const [currentPosition, setCurrentPosition] = React.useState<any>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const beds = useQuery(
    ['bedsOverview', authContext.authState.token],
    async () => {
      if (!authContext.authState.token) return null;
      return DefaultService.getPositionWithAttributionStatus(
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile?.currentDepartment
      );
    },
    {
      refetchInterval: 3000,
      refetchOnWindowFocus: true,
    }
  );



  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const renderItem = (item: any) => {
    return (
      <Item
        setModalVisible={setModalVisible}
        setCurrentPosition={setCurrentPosition}
        title={`${item.position?.name}`}
        occupedBy={item.currentPatient?.name}
        key={item.position.GSIPK}
        id={item.position.GSIPK}
      />
    );
  };

  if (beds.isLoading) return <Loader />;
  return (
    <>
      <View>
        <Title h2 mvS>
          Lits occupés : {beds.data?.records.filter((item: any) => item.currentPatient).length}/
          {beds.data?.records?.length}
        </Title>
        <View>
          {beds.data?.records.length === 0 ? <NoActivityIndicator /> : <></>}
          {beds.data?.records.map((item: any) => renderItem(item))}
        </View>
      </View>
      {/*<FreeBedModal isModalVisible={isModalVisible} toggleModal={toggleModal} positionId={currentPosition}/>*/}
    </>
  );
};

export default BedsOverview;

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: '#ffffff',
    borderRadius: Radius.SMALL,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
    marginTop: 10,
  },
});
