import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Paragraph, Title } from '../../../components/texts';
import { Button } from '../../../components/buttons';
import { Colors } from '../../../styles/theme';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ROUTES } from '../../../router/Routes';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';

const LeaveBedScreen = () => {
  const [loading, setLoading] = React.useState(false);
  const [position, setPosition] = React.useState<any>({});
  const { displaySnackBar } = useSnackBarContext();
  const route = useRoute();
  const { positionId } = route.params as any;
  const { navigate } = useNavigation();


  const leaveBed = async () => {
    setLoading(true);
    try {
    /*  const response = await positionsContext.releasePosition(positionId);
      setLoading(false);
      if (response) {
        displaySnackBar('Position libérée', 'SUCCESS');
        navigate(ROUTES.SERVICE_SCREEN.name);
      }*/
    } catch (error) {
      setLoading(false);
      displaySnackBar('Impossible de libérer la position', 'ERROR');
    }
  };

  return (
    <MainLayout backButton serviceCard>
      <ModuleContentView>
        <View style={styles.container}>
          <Title h2 secondary center>
            Libérer la position
          </Title>
          <Image source={require('../../../assets/images/requests/leavebed.png')} style={{ width: 350, height: 220 }} />
          <Title h2 primary>
            {position ? `${position.room?.name} - ${position.name}` : <></>}
          </Title>
          <View style={styles.textContainer}>
            <Paragraph>
              Si vous libérez cette position, les requêtes en attente et en cours de traitement s’archiveront dans
              l’historique sous forme de requêtes annulées. Êtes vous sûr(e) de vouloir libérer la position ?{' '}
            </Paragraph>
          </View>
          <View style={styles.buttonContainer}>
            <Button label="Libérer" secondary onPress={leaveBed} loading={loading} />
          </View>
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { LeaveBedScreen };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginVertical: 20,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.WHITE,
    borderRadius: 20,
    width: '100%',
    padding: 20,
    marginVertical: 10,
  },
  textDangerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#f3a9ba',
    borderRadius: 20,
    width: '100%',
    padding: 20,
    marginVertical: 10,
    borderColor: Colors.SECONDARY,
    borderWidth: 1,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginVertical: 20,
  },
});
