import React from 'react';
import Router from './src/router/Router';
import { Provider as PaperProvider } from 'react-native-paper';
import SnackBarNetwork from './src/components/feedbacks/snackBar/snackBarNetwork';
import AppContextProvider from './src/providers/AppContextProvider';
// @ts-ignore
import { stopReportingRuntimeErrors } from 'react-error-overlay';
import { Amplify } from 'aws-amplify';
import { onlineManager } from '@tanstack/react-query';
import NetInfo from '@react-native-community/netinfo';
import { useEffect } from 'react';
import { AppState, Platform } from 'react-native';
import type { AppStateStatus } from 'react-native';
import { focusManager } from '@tanstack/react-query';
import * as Sentry from 'sentry-expo';
import ENV from "./env.json"

onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(!!state.isConnected);
  });
});

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-3',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: ENV.USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: ENV.userPoolWebClientId,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

if (process.env.NODE_ENV === 'development' && Platform.OS === 'web') {
  stopReportingRuntimeErrors(); // disables error overlays
}

Sentry.init({
  dsn: 'https://e2163482a2ab4a47a09936e24fc7dc2a@o830499.ingest.sentry.io/4504615845494784',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

export default function App() {
  function onAppStateChange(status: AppStateStatus) {
    if (Platform.OS !== 'web') {
      focusManager.setFocused(status === 'active');
    }
  }

  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChange);

    return () => subscription.remove();
  }, []);

  return (
    <PaperProvider>
      <AppContextProvider>
        <>
          <SnackBarNetwork />
          <Router />
        </>
      </AppContextProvider>
    </PaperProvider>
  );
}
