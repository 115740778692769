import { PriorityEnum } from '../../types/enums/PriorityEnum';

export const getPriority = (priority: PriorityEnum) => {
  switch (priority) {
    case PriorityEnum.LOW:
      return 'Basse';
    case PriorityEnum.NORMAL:
      return 'Normal';
    case PriorityEnum.HIGH:
      return 'Haute';
    case PriorityEnum.EMERGENCY:
      return 'URGENT';
  }
};

export const getPriorityValue = (priority: PriorityEnum) => {
  switch (priority) {
    case PriorityEnum.LOW:
      return 4;
    case PriorityEnum.NORMAL:
      return 3;
    case PriorityEnum.HIGH:
      return 2;
    case PriorityEnum.EMERGENCY:
      return 1;
  }
};


export const getPriorityString = (priority: number):PriorityEnum => {
  switch (priority) {
    case 4:
      return PriorityEnum.LOW;
    case 3:
      return PriorityEnum.NORMAL;
    case 2:
      return PriorityEnum.HIGH;
    case 1:
      return PriorityEnum.EMERGENCY;
    default:
      return PriorityEnum.LOW
  }
}