import React from 'react';
import { Platform, ScrollView, StyleSheet, View, Text } from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { useRoute } from '@react-navigation/native';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { Paragraph, Title } from '../../../components/texts';
import { Colors } from '../../../styles/theme';
import { getStatus } from '../../../utils/helpers/getStatus';
import { getPriority, getPriorityString } from '../../../utils/helpers/getPriority';

const DetailsHistoryRequestScreen = () => {
  const route = useRoute();
  const { state } = route.params as any;


  const renderComment = (commentary: string | undefined) => {
    if (commentary) {
      return (
        <View>
          <ScrollView style={styles.commentaryContainer}>
            <Text style={styles.sectionTitle}>Commentaire patient</Text>
            <Paragraph grey>{commentary}</Paragraph>
          </ScrollView>
        </View>
      );
    }
  };

  const renderTakeBy = () => {
    let takeBy = null;
    let takeAt = null;

    if (state.demand?.hasOwnProperty('takeBy')) {
      takeBy = state.demand.takeBy;
      takeAt = new Date(state.demand.takeAt);
    }
    if (state.demand?.hasOwnProperty('canceledBy')) {
      takeBy = state.demand.canceledBy;
      takeAt = new Date(state.demand.canceledAt);
    }
    if (state.demand?.hasOwnProperty('refusedBy')) {
      takeBy = state.demand.refusedBy;
      takeAt = new Date(state.demand.refusedAt);
    }

    if (!takeBy) return <></>;
    return (
      <>
        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Text style={styles.sectionTitle}>Par</Text>
          </View>
          <Title h3>{takeBy.hasOwnProperty('name') && `${takeBy.name} ${takeBy.firstname}`}</Title>
        </View>

        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Text style={styles.sectionTitle}>Prise en charge à </Text>
          </View>
          <Title h3>
            {takeAt && takeAt.getHours()}h{takeAt.getMinutes() < 10 ? `0${takeAt.getMinutes()}` : takeAt.getMinutes()}
          </Title>
        </View>
      </>
    );
  };

  return (
    <MainLayout
      backButton
      requestCardHeaderData
      request={state.demand ? state.demand : false}
      category={state.category ? state.category : false}
      parentCategory={state.parentCategory ? state.parentCategory : false}
      backToTop
    >
      <ModuleContentView>
        <View>
          {renderComment(state.demand.comment)}
          <View>
            <View style={styles.detailsContainer}>
              <View style={styles.detailsSubContainer}>
                <View style={styles.detailsTitleContainer}>
                  <Text style={styles.sectionTitle}>Statut</Text>
                </View>
                <Title h3>{getStatus(state.demand.status)}</Title>
              </View>
              <View style={styles.detailsSubContainer}>
                <View style={styles.detailsTitleContainer}>
                  <Text style={styles.sectionTitle}>Priorité</Text>
                </View>
                <Title h3>{getPriority(getPriorityString(state.demand.priority))}</Title>
              </View>
              {state.demand.status === StatusEnum.TERMINATED ? renderTakeBy() : null}
            </View>
          </View>
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { DetailsHistoryRequestScreen };

const styles = StyleSheet.create({
  commentaryContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 1,
    shadowRadius: 11.95,
    elevation: 5,
    padding: 15,
    minHeight: 80,
    marginVertical: 20,
  },
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 0.3,
    shadowRadius: 11.95,
    elevation: 5,
    display: 'flex',
    padding: 15,
  },
  detailsSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: Platform.OS === 'web' ? 0 : 20,
  },
  detailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  sectionTitle: {
    color: Colors.GREY,
    fontSize: 17,
    fontWeight: 'bold',
  },
});
