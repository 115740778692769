/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Category } from './Category';

export type Demand = {
    intensity?: number;
    createdAt: string;
    takeAt?: string;
    canceldAt?: string;
    takeBy?: {
        firstname?: string;
        name?: string;
    };
    patient: {
        name: string;
        id?: string;
    };
    comment?: string;
    position?: string;
    category: Category;
    categoryId?: string;
    finishAt?: string;
    status: Demand.status;
};

export namespace Demand {

    export enum status {
        NEW = 'new',
        PROCESSING = 'processing',
        BOOSTED = 'boosted',
        REBOOSTED = 'reboosted',
        TERMINATED = 'terminated',
        CANCELED = 'canceled',
    }


}

