import React from 'react';
import { StyleSheet, View } from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { Button } from '../../../components/buttons';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import ActivityDetails from '../../../modules/dashboard/requests/ActivityDetails.module';
import GeneralDetails from '../../../modules/dashboard/requests/GeneralDetails.module';
import { useDemandsContext } from '../../../providers/DemandsProvider';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { ROUTES } from '../../../router/Routes';
import { Title } from '../../../components/texts';
import { useAuthContext } from '../../../providers/AuthProvider';
import { DefaultService } from '../../../generated/api';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '../../../providers/UserProvider';
import getLastCategoryDataFromCategoriesPath from '../../../utils/helpers/getLastCategoryDataFromCategoriesPath';
import { doestItHaveChildren } from '../../../utils/helpers/doestItHaveChildren';
import { positionStatusEnum } from '../../../types/enums/PositionStatusEnum';

const DetailsRequestScreen = () => {
  const demandsContext = useDemandsContext();
  const authContext = useAuthContext();
  const userContext = useUserContext();
  const [mode, setMode] = React.useState('general');
  const [request, setRequest] = React.useState<any>(false);
  const [category, setCategory] = React.useState<any>(false);
  const [parentCategory, setParentCategory] = React.useState<any>(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const route = useRoute();
  const { id } = route.params as any;
  const { navigate } = useNavigation();
  const departmentDemands = useQuery(
    ['departmentDemands', authContext.authState.token],
    async () => {
      if (!authContext.authState.token) return null;
      return DefaultService.getDepartmentDemandsCaregiver(
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile?.currentDepartment
      );
    },
    {
      refetchInterval: 10000,
    }
  );
  const takeDemand = async (id: string) => {
    setLoading(true);
    const patientStatus = await userContext.getPatientStatus(request.patient.id);

    if (patientStatus === positionStatusEnum.VALIDATED) {
      const updatedDemandResponse = await DefaultService.acceptDemand(
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile?.currentDepartment,
        id
      );
      if (updatedDemandResponse.demand.ID) {
        setRequest({ ...request, status: updatedDemandResponse.demand.status });
      }
    } else if (patientStatus === positionStatusEnum.WAIT_FOR_VALIDATION) {
      setLoading(false)
      return navigate(ROUTES.CONFIRM_NEW_PATIENT_SCREEN.name, { request: request, demandId: id });
    } else {
      const updatedDemandResponse = await DefaultService.acceptDemand(
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile?.currentDepartment,
        id
      );
      if (updatedDemandResponse.demand.ID) {
        setRequest({ ...request, status: updatedDemandResponse.demand.status });
      }
    }

    await departmentDemands.refetch();
    navigate(ROUTES.HOME_SCREEN.name);
    setLoading(false);
  };

  const finishDemand = async (id: string) => {
    setLoading(true);
    const finishDemandResponse = await DefaultService.finishDemand(
      `Bearer ${authContext.authState.token}`,
      userContext.userState.profile?.currentDepartment,
      id
    ).catch(() => {
      setLoading(false);
    });
    if (finishDemandResponse.demand.ID) {
      setRequest({ ...request, status: finishDemandResponse.demand.status });
      await departmentDemands.refetch();

      navigate(ROUTES.HOME_SCREEN.name);
    }
    setLoading(false);
  };

  const getActionButton = (status: StatusEnum) => {
    switch (status) {
      case StatusEnum.NEW:
      case StatusEnum.BOOSTED:
      case StatusEnum.REBOOSTED:
        return (
          <View style={{ marginTop: 30 }}>
            <Button secondary label={"Je m'en occupe"} onPress={() => takeDemand(id)} loading={loading} />
          </View>
        );
      case StatusEnum.PROCESSING:
        return (
          <View style={{ marginTop: 30 }}>
            <Button success label={"C'est fait"} onPress={() => finishDemand(id)} loading={loading} />
          </View>
        );
      case StatusEnum.TERMINATED:
        return (
          <View style={{ marginTop: 30 }}>
            <Button disable label={'Demande terminée.'} />
          </View>
        );
      case StatusEnum.CANCELLED:
        return (
          <View style={{ marginTop: 30 }}>
            <Button disable label={'Demande annulée'} />
          </View>
        );
    }
  };

  React.useEffect(() => {
    if (departmentDemands.data || !id) {
      const demand = departmentDemands?.data?.records.find((demand: any) => {
        return demand.ID === id;
      });
      if (demand) {
        setRequest(demand);
      }
    }
  }, [id, departmentDemands.data]);

  React.useEffect(() => {
    if (!request.category) return;
    const { category, parentCategory } = getLastCategoryDataFromCategoriesPath(
      request.category,
      doestItHaveChildren(request.category)
    );
    setCategory(category);
    setParentCategory(parentCategory);
  }, [request]);

  return (
    <MainLayout
      backButton
      requestCardHeaderData
      request={request ? request : false}
      category={category ? category : false}
      parentCategory={parentCategory ? parentCategory : false}
      backToTop
    >
      <ModuleContentView>
        {error !== '' ? (
          <Title h3 secondary center>
            {error}
          </Title>
        ) : (
          <></>
        )}
        {mode === 'general' ? (
          <GeneralDetails
            category={category ? category : false}
            parentCategory={parentCategory ? parentCategory : false}
            request={request ? request : false}
          />
        ) : (
          <ActivityDetails />
        )}
        {getActionButton(request.status)}
      </ModuleContentView>
    </MainLayout>
  );
};

export { DetailsRequestScreen };
