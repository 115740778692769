import React from 'react';
import { Entypo, EvilIcons } from '@expo/vector-icons';
import { Title } from '../texts';
import { StatusEnum } from '../../types/enums/StatusEnum';
import { getUsernameOrPreferredUsername } from '../../utils/helpers/validations/getUsernameOrPreferredUsername';
import MainCardContainer from './MainCardContainer';
import { Container } from '../../layouts';
import { Label } from '../texts/Label';
import { View } from 'react-native';
import { PriorityEnum } from '../../types/enums/PriorityEnum';
import getHourFromDate from '../../utils/helpers/getHourFromDate';
import { ta } from 'date-fns/locale';

interface requestCardInterface {
  date: any;
  category: string;
  status: StatusEnum;
  priority: PriorityEnum;
  caregiver?: any;
  position?: any;
  history?: any;
}

/**
 *
 * @param icon
 * @param date
 * @param category
 * @param status
 */
const HistoryCard = ({ history }: requestCardInterface) => {
  if (!history) return null;
  let takeAt;

  if (history.demand.hasOwnProperty('takeAt')) {
    takeAt = new Date(history.demand.takeAt);
  } else if (history.demand.hasOwnProperty('canceledAt')) {
    takeAt = new Date(history.demand.canceledAt);
  }
  return (
    <MainCardContainer noPadding backgroundColor={'#e5faff'}>
      <Container flexRow alignCenter justifyCenter flex={1} backgroundColor={'lightgrey'} height={'100%'}>
        {history.demand.hasOwnProperty('takeBy') && (
          <Title h1 white>
            {history.demand.takeBy?.firstname}
          </Title>
        )}
        {history.demand.hasOwnProperty('canceledBy') && (
          <Title h1 white>
            {history.demand.canceledBy?.firstname} {history.demand.canceledBy?.lastname}
          </Title>
        )}
      </Container>
      <Container flexCol flex={3} alignStart justifyBetween paddingHorizontal={5}>
        <Title h2 mvXS secondary>
          {history.demand.position ? `${history.demand.position.name}` : 'Hospitalink'}
        </Title>

        <Label color={'white'}>{history.category.name.FR}</Label>
        <Container flexRow alignCenter justifyBetween>
          <Container flexRow alignCenter justifyStart>
            <EvilIcons name="clock" size={24} color="black" />
            <Title h3 mvXS>
              {
               takeAt && `${getHourFromDate(takeAt)}`
              }
            </Title>
          </Container>
        </Container>
      </Container>

      <View
        style={{
          height: 120,
          width: 120,
          position: 'absolute',
          right: -55,
          bottom: -55,
          backgroundColor: history.demand.status === StatusEnum.TERMINATED ? 'green' : 'red',
          borderRadius: 70,
          display: 'flex',
        }}
      >
        {history.demand.status === StatusEnum.TERMINATED ? (
          <Entypo name="check" size={30} color="white" style={{ marginTop: 20, marginLeft: 20 }} />
        ) : (
          <Entypo name="cross" size={30} color="white" style={{ marginTop: 20, marginLeft: 20 }} />
        )}
      </View>
    </MainCardContainer>
  );
};

export default HistoryCard;
