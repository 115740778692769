import React from 'react';
import RequestsListsModule from '../../modules/dashboard/requests/RequestsLists.module';
import MainLayout from '../../layouts/dashboard/MainLayout';
import ModuleContentView from '../../layouts/dashboard/ModuleContentView';
import { StatusEnum } from '../../types/enums/StatusEnum';
import { ActivityIndicator, Image, Platform } from 'react-native';
import { Title } from '../../components/texts';
import { useUserContext } from '../../providers/UserProvider';
import { Container } from '../../layouts';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../providers/AuthProvider';
import { DefaultService } from '../../generated/api';
import { Loader } from '../../components/loader/Loader';

const PatientScreen = (): React.ReactElement => {
  const userContext = useUserContext();
  const authContext = useAuthContext();
  const departmentDemands = useQuery(
    ['departmentDemands', authContext.authState.token],
    async () => {
      if (!authContext.authState.token) return null;
      return DefaultService.getDepartmentDemandsCaregiver(
        `Bearer ${authContext.authState.token}`,
        userContext.userState.profile?.currentDepartment
      );
    },
    {
      refetchInterval: 3000,
      refetchOnWindowFocus: true,
    }
  );

  const renderRequestModule = () => {
    if (departmentDemands.isLoading) return null;
    if (
      departmentDemands.data?.records?.filter(
        (request: any) =>
          request.status.toLowerCase() !== StatusEnum.TERMINATED &&
          request.status.toLowerCase() !== StatusEnum.CANCELLED
      ).length === 0
    ) {
      return <NoRequestIndicator />;
    } else {
      return <RequestsListsModule demands={departmentDemands.data?.records} />;
    }
  };

  const NoRequestIndicator = () => {
    return (
      <Container flexCol alignCenter justifyCenter marginVertical={50}>
        <Image source={require('../../assets/images/requests/noRequest.png')} style={{ width: 70, height: 70 }} />
        <Title h1 center color={'#032E5C'}>
          Vous êtes à jour
        </Title>
        <Title h3 center color={'gray'} mvXS>
          Profitez de ce moment ☀️
        </Title>
      </Container>
    );
  };

  if (authContext.authState.isConnected && !userContext.userState.profile) return <></>;
  return (
    <MainLayout serviceCard lockButton={Platform.OS === 'web'}>
      {departmentDemands.isLoading && <Loader />}
      <ModuleContentView>{renderRequestModule()}</ModuleContentView>
    </MainLayout>
  );
};

export { PatientScreen };
