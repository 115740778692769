const ROUTES = {
  SPLASH_SCREEN: { name: 'splashScreen', title: 'Splash Screen' },
  LOGIN_SCREEN: { name: 'Hospitalink - écran de connexion', title: 'Écran de connexion' },
  PIN_SCREEN: { name: 'Hospitalink - Code pin', title: 'Écran de code pin' },
  PIN_SCREEN_WEB: { name: 'Hospitalink - Code pin web', title: 'Écran de code pin web' },
  HOME_SCREEN: {
    name: "Hospitalink - Page d'accueil",
    title: "Page d'accueil",
  },
  SERVICE_SCREEN: {
    name: 'Hospitalink - Gestion du service',
    title: 'Hospitalink - Gestion du service',
  },
  REQUEST_DETAILS_SCREEN: { name: 'Hospitalink - Détails de la requête', title: 'Details de la requête' },
  REQUEST_HISTORY_DETAILS_SCREEN: { name: 'Hospitalink - Détails de la requête historique', title: 'Details de la requête historique' },
  PROFILE_SCREEN: { name: 'Hospitalink - Profil', title: 'Profile' },
  NEW_PATIENT_SCREEN: { name: 'Hospitalink - Nouveau patient', title: 'Arrivée de nouveau patient' },
  CONFIRM_NEW_PATIENT_SCREEN: {
    name: 'Hospitalink - Confirm Nouveau patient',
    title: 'Confirmer l arrivée de nouveau patient',
  },
  ADD_CAREGIVER_SCREEN: { name: 'Hospitalink - Ajouter un soignant', title: 'ajouter un soignant' },
  UPDATE_CAREGIVER_SCREEN: { name: "Hospitalink - Mise à jour d'un soignant", title: "mise à jour d'un soignant" },
  LEAVE_BED_SCREEN: { name: 'Hospitalink - Libérer le lit', title: 'Libérer le lit' },
  CGU_SCREEN: { name: "Hospitalink - Conditions générales d'utilisation", title: "Conditions générales d'utilisation" },
  FORGOT_PASSWORD_SCREEN: { name: 'Hospitalink - Mot de passe oublié', title: 'Mot de passe oublié' },
  RESET_PASSWORD_SCREEN: { name: 'Hospitalink - Mot de passe oublié', title: 'Mot de passe oublié' },
  CHANGE_PASSWORD_SCREEN: { name: 'Hospitalink - Modifier mon mot de passe', title: 'Modifier mon mot de passe' },
  AUTHORIZE_NOTIFICATIONS_SCREEN: {
    name: 'Hospitalink - Autorisation des notifications',
    title: 'Autorisation des notifications',
  },
  UPDATE_MY_PSEUDO_SCREEN: { name: 'Hospitalink - Mise à jour du pseudo', title: 'mise à jour du pseudo' },
  UPDATE_MY_PIN_CODE: { name: 'Hospitalink - Mise à jour du code pin', title: 'mise à jour du code pid' },
  CHANGE_CREDENTIALS_FIRST_LOGIN_SCREEN: {
    name: 'Hospitalink - Changement de credentials première connexion',
    title: 'Changement de credentials première connexion',
  },
  CHANGE_PINCODE_FIRST_LOGIN_SCREEN: {
    name: 'Hospitalink - Changement de code pin première connexion',
    title: 'Changement de code pin première connexion',
  },
  CONTACT_SCREEN: { name: 'Hospitalink - Contact', title: 'Contact' },
  ADDINFORMATION_SCREEN: { name: 'Hospitalink - Information', title: 'AddInformation' },
  POLITIQUE_SCREEN: { name: 'Hospitalink - Politique', title: 'Politique' },
  CONDITION_GENERAL_SCREEN: { name: 'Hospitalink - conditionGeneral', title: 'Condition general' },
  SERVICE_HISTORY_SCREEN: {
    name: 'Hospitalink - Historique du service',
    title: 'Historique du service',
  },
  ERROR_SCREEN: { name: 'Hospitalink - erreur', title: "écran d'erreur" },
  CHAT_SCREEN: { name: 'Hospitalink - Hospiteam', title: 'Discussions' },
};

export { ROUTES };
