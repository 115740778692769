import React from 'react';
import { ICON } from '../../../env.json';
import { Image } from 'react-native';

interface SvgInterface {
  icon: string;
  height?: string;
  width?: string;
}

const Svg = ({ icon, height, width }: SvgInterface) => {
  const [error, setError] = React.useState(false);
  const uri = `${ICON}${icon}`;

  if (error){
    return <Image source={require('../../assets/images/requests/urgency.png')} style={{ width: 80, height: 80 }} />;
  }

  return <Image source={{ uri }} style={{ height: 80, width: 80 }} onError={({nativeEvent: {error} })  => {
    setError(true)
  }} />;
};

export default Svg;
