import React, { Context, createContext, useContext } from 'react';

import ReconnectingWebSocket from 'reconnecting-websocket';
import { useAuthContext } from './AuthProvider';
import { useNotificationsContext } from './NotificationsProvider';
import { URL_WS } from '../../env.json';
import { useDemandsContext } from './DemandsProvider';
import { formatDemandFromWebsocket } from '../utils/helpers/formatDemandFromWebsocket';
import { Audio } from 'expo-av';
import { PriorityEnum } from '../types/enums/PriorityEnum';
import { useSnackBarContext } from './SnackBarProvider';
import { useChatContext } from './ChatProvider';

interface WebsocketContextContextElements {
  isWebsocketConnected: boolean;
  connection: () => void;
  rws: any;
}

const WebsocketContext: Context<WebsocketContextContextElements> = createContext({} as WebsocketContextContextElements);

const WebsocketProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isWebsocketConnected, setIsWebsocketConnected] = React.useState<boolean>(false);
  const authContext = useAuthContext();
  const demandsContext = useDemandsContext();
  const notificationsContext = useNotificationsContext();
  const snackbarContext = useSnackBarContext();
  const chatContext = useChatContext();

  let rws: any;



  async function playMessageSound() {
    const { sound } = await Audio.Sound.createAsync(require('../assets/audios/case-closed-531.mp3'));

    await sound.playAsync();
  }



  const connection = () => {
    const token = authContext.authState.token;
    try {
      rws = new ReconnectingWebSocket(URL_WS);
      rws.addEventListener('open', () => {
        rws.send(JSON.stringify({ action: 'authentication', token: token }));
        setIsWebsocketConnected(true);
      });
      rws.addEventListener('message', (message: any) => handleActionWebSocket(message));
      rws.addEventListener('close', () => setIsWebsocketConnected(false));
    } catch (error) {
      console.error(error);
      setIsWebsocketConnected(false);
    }
  };

  const handleActionWebSocket = async (message: any) => {
    switch (JSON.parse(message.data).action) {
      case 'new':
        await handleEntityNewWebSocket(message);
        await notificationsContext.schedulePushNotification();
        break;
      case 'update':
        await handleEntityUpdateWebSocket(message);
        await notificationsContext.schedulePushNotification();
        break;
      // default:
      //   await demandsContext.getMyServiceDemands();
      //   await positionsContext.getDepartmentRequestsPositions();
    }
  };

  const handleEntityNewWebSocket = async (message: any) => {
    switch (JSON.parse(message.data).entity) {
      case 'Demand':
        demandsContext.setServiceDemands(formatDemandFromWebsocket(JSON.parse(message.data).body));
        await displayNotification(message, 'Demand');
        await positionsContext.getDepartmentRequestsPositions();
        break;
      case 'PositionRequest':
        positionsContext.setPositionRequest(JSON.parse(message.data).body);
        await displayNotification(message, 'Position');
        break;
      case 'ChatMessageEntity':
        await chatContext.getMessages(1);
        chatContext.setNumberOfNewMessages((numberOfNewMessages: number) => numberOfNewMessages + 1);
        await playMessageSound();
        break;
    }
  };

  const handleEntityUpdateWebSocket = async (message: any) => {
    switch (JSON.parse(message.data).entity) {
      case 'Demand':
        demandsContext.updateServiceDemand(formatDemandFromWebsocket(JSON.parse(message.data).body));
        break;
      case 'PositionRequest':
        positionsContext.setPositionRequest(JSON.parse(message.data).body);
        break;
      case 'Callback':
        await displayNotification(null, 'Demand');
    }
  };

  React.useEffect(() => {
    if (!authContext.authState.isConnected) return;
    connection();
    return () => {
      rws.close();
    };
  }, [authContext.authState.isConnected]);

  return (
    <WebsocketContext.Provider
      value={{
        rws,
        connection,
        isWebsocketConnected,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

const useWebsocketContext = (): WebsocketContextContextElements => {
  return useContext(WebsocketContext);
};

export { WebsocketProvider, useWebsocketContext };
