import React, { Context, createContext, useContext } from 'react';
import { useAuthContext } from './AuthProvider';
import { registerForPushNotificationsAsync } from '../notifications/registerPushNotification';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { useAppStateContext } from './AppstateProvider';
import { DefaultService } from '../generated/api';

interface NotificationsContextElements {
  expoPushToken: string;
  schedulePushNotification: () => void;
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const NotificationsContext: Context<NotificationsContextElements> = createContext({} as NotificationsContextElements);

const NotificationsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const authContext = useAuthContext();
  const appStateContext = useAppStateContext();

  const [expoPushToken, setExpoPushToken] = React.useState<any>('');
  const [notification, setNotification] = React.useState<any>(false);

  const notificationListener: any = React.useRef();
  const responseListener: any = React.useRef();

  const schedulePushNotification = async () => {
    if (Platform.OS === 'web') return;
    if (appStateContext.appStateVisible === 'active') return;
    await Notifications.scheduleNotificationAsync({
      content: {
        title: 'Vous avez du nouveau! 📬',
        body: 'Rendez-vous sur votre tableau de bord !',
        data: { data: 'goes here' },
        priority: 'high',
      },
      trigger: { seconds: 1 },
    });
  };

  React.useEffect(() => {
    if (!authContext.authState.isConnected) return;
    if (Platform.OS === 'web') return;
    registerForPushNotificationsAsync().then((token) => {
      if (token) {
        setExpoPushToken(token);
        DefaultService.addDeviceToken(`${authContext.authState.token}`, { token }).catch((e) => {
          //TODO: bug
          //console.log(e);
        });
      }
    });

    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      setNotification(notification);
    });
    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
    });
    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [authContext.authState.isConnected]);

  return (
    <NotificationsContext.Provider value={{ expoPushToken, schedulePushNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotificationsContext = (): NotificationsContextElements => {
  return useContext(NotificationsContext);
};

export { NotificationsProvider, useNotificationsContext };
