import React from 'react';
import { Image, Linking, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { Title } from '../../../components/texts';
import { Colors } from '../../../styles/theme';
import { AntDesign } from '@expo/vector-icons';
import { ROUTES } from '../../../router/Routes';
import { useNavigation } from '@react-navigation/native';
import { getWebviewOrLink } from '../../../utils/helpers/getWebviewOrLink';

const navigateToRatingStore = () => {
  if (Platform.OS != 'ios') {
    Linking.openURL(`market://details?id=com.hospitalink.soignant`).catch(() =>
      alert('Please check for the Google Play Store')
    );
  } else {
    Linking.openURL(`itms-apps://itunes.apple.com/in/app/apple-store/1532939357?mt=8`).catch(() =>
      alert('Please check for the App Store')
    );
  }
};

const MyCaregiverProfileScreen = () => {
  const { navigate } = useNavigation();

  return (
    <MainLayout backButton serviceCard>
      <ModuleContentView>
        <View>
          <Title h2>Informations</Title>
          <View style={styles.detailsContainer}>
            <View style={styles.detailsSubContainer}>
              <View style={styles.detailsTitleContainer}>
                <Image
                  source={require('../../../assets/images/requests/iconCode.png')}
                  style={{ width: 50, height: 50 }}
                />
                <TouchableOpacity onPress={() => navigate(ROUTES.UPDATE_MY_PIN_CODE.name)}>
                  <Title h3 mhS>
                    Changer mon code
                  </Title>
                </TouchableOpacity>
              </View>
              <AntDesign name="right" size={18} color="gray" />
            </View>
            <View style={styles.detailsSubContainer}>
              <View style={styles.detailsTitleContainer}>
                <Image
                  source={require('../../../assets/images/requests/iconCode.png')}
                  style={{ width: 50, height: 50 }}
                />
                <TouchableOpacity onPress={() => navigate(ROUTES.CHANGE_PASSWORD_SCREEN.name)}>
                  <Title h3 mhS>
                    Changer mon mot de passe
                  </Title>
                </TouchableOpacity>
              </View>
              <AntDesign name="right" size={18} color="gray" />
            </View>
          </View>
        </View>
        <View>
          <Title h2>Support et confidentialité</Title>
          <View style={styles.detailsContainer}>
            <TouchableOpacity
              onPress={() =>
                getWebviewOrLink({
                  navigate,
                  link: 'https://www.hospitalink.fr/soignant/faq',
                  route: ROUTES.CONTACT_SCREEN.name,
                })
              }
              style={styles.detailsSubContainer}
            >
              <View style={styles.detailsTitleContainer}>
                <Image
                  source={require('../../../assets/images/requests/iconContact.png')}
                  style={{ width: 50, height: 50 }}
                />
                <Title h3 mhS>
                  Aide et contact
                </Title>
              </View>
              <AntDesign name="right" size={18} color="gray" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigateToRatingStore()} style={styles.detailsSubContainer}>
              <View style={styles.detailsTitleContainer}>
                <Image
                  source={require('../../../assets/images/requests/iconNote.png')}
                  style={{ width: 50, height: 50 }}
                />
                <Title h3 mhS>
                  Noter hospitalink
                </Title>
              </View>
              <AntDesign name="right" size={18} color="gray" />
            </TouchableOpacity>
          </View>
          <Title h3 center>
            Version 2.0
          </Title>
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export { MyCaregiverProfileScreen };

const styles = StyleSheet.create({
  commentaryContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 1,
    shadowRadius: 11.95,
    elevation: 5,
    padding: 15,
  },
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 0.3,
    shadowRadius: 11.95,
    elevation: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  detailsSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  detailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
