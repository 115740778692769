import React from 'react';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { StyleSheet, View } from 'react-native';
import { Title } from '../../../components/texts';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import { Colors } from '../../../styles/theme';
import { InputText } from '../../../components/inputs/InputText';
import { Button } from '../../../components/buttons';
import { useUserContext } from '../../../providers/UserProvider';
import { useSnackBarContext } from '../../../providers/SnackBarProvider';
import { getUsernameOrPreferredUsername } from '../../../utils/helpers/validations/getUsernameOrPreferredUsername';
import { DefaultService } from '../../../generated/api';

const UpdateMyPseudoScreen = () => {
  const userContext = useUserContext();
  const { displaySnackBar } = useSnackBarContext();
  const [pseudo, setPseudo] = React.useState<string>(getUsernameOrPreferredUsername(userContext.userState.profile));
  const [loading, setLoading] = React.useState(false);

  const handleSubmitPseudo = async () => {
    setLoading(true);
    const response = await DefaultService.updateCaregiver(`Bearer ${userContext.userState.token}`, userContext.userState.profile?.currentDepartment, userContext.userState.profile?.id, {
      pseudo: pseudo,
    });
    if (response) {
      displaySnackBar('Modification enregistrée !', 'SUCCESS');
    } else {
      displaySnackBar('Une erreur est survenue', 'ERROR');
    }
    setLoading(false);
  };

  return (
    <MainLayout backButton serviceCard>
      <ModuleContentView>
        <View>
          <Title h2>Informations</Title>
          <View style={styles.detailsContainer}>
            <Title h3>Changer de pseudo (le nom que les autres soignant voient)</Title>
            <InputText label={'Pseudo'} value={pseudo} onChangeText={(value) => setPseudo(value)} />
            <Button label={'Valider'} success onPress={handleSubmitPseudo} loading={loading} />
          </View>
        </View>
      </ModuleContentView>
    </MainLayout>
  );
};

export default UpdateMyPseudoScreen;

const styles = StyleSheet.create({
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    padding: 20,
    marginVertical: 10,
  },
});
