import React from 'react';

import { AuthProvider } from './AuthProvider';
import { WebsocketProvider } from './WebsocketProvider';
import { NotificationsProvider } from './NotificationsProvider';
import { UserProvider } from './UserProvider';
import { DemandsProvider } from './DemandsProvider';
import { CaregiversProvider } from './CaregiversProvider';
import { AppStateProvider } from './AppstateProvider';
import { SnackBarProvider } from './SnackBarProvider';
import { ApolloProvider } from '@apollo/client';
import graphClient from '../api/graphAPI/graphClient/graphClient';
import { DepartmentProvider } from './DepartmentProvider';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()


const MainProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
    <ApolloProvider client={graphClient}>
      <AppStateProvider>
        <SnackBarProvider>
          <AuthProvider>
            <DepartmentProvider>
                <UserProvider>
                  <DemandsProvider>
                      <CaregiversProvider>
                        <NotificationsProvider>
                            <WebsocketProvider>{children}</WebsocketProvider>
                        </NotificationsProvider>
                      </CaregiversProvider>
                  </DemandsProvider>
                </UserProvider>
            </DepartmentProvider>
          </AuthProvider>
        </SnackBarProvider>

        </AppStateProvider>
    </ApolloProvider>
    </QueryClientProvider>
  );
};

export default MainProvider;
