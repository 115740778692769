/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Category = {
    priorityName?: string;
    icon: string;
    emergency: boolean;
    priority: number;
    childs?: Array<Category>;
    parentId: any;
    intensityRange?: {
        high?: number;
        low?: number;
        medium?: number;
    };
    tags: Array<string>;
    categoryType?: Category.categoryType;
    going_coming: boolean;
    name: {
        AR?: string;
        PT?: string;
        EN?: string;
        IT?: string;
        FR?: string;
        ES?: string;
    };
    id: string;
    slug: string;
    order: number;
};

export namespace Category {

    export enum categoryType {
        MEDICAL = 'medical',
        PARAMEDICAL = 'paramedical',
        HOTELIER = 'hotelier',
        OTHER = 'other',
    }


}

