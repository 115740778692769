/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { addDeviceTokenBody } from '../models/addDeviceTokenBody';
import type { addDeviceTokenResponse } from '../models/addDeviceTokenResponse';
import type { caregiverDeleteResponse } from '../models/caregiverDeleteResponse';
import type { caregiverInfos } from '../models/caregiverInfos';
import type { caregiverPinCheck } from '../models/caregiverPinCheck';
import type { createCaregiverResponse } from '../models/createCaregiverResponse';
import type { createCaregiverSchema } from '../models/createCaregiverSchema';
import type { CreateDemandResponse } from '../models/CreateDemandResponse';
import type { CreateDemandSchema } from '../models/CreateDemandSchema';
import type { deleteDeviceTokenResponse } from '../models/deleteDeviceTokenResponse';
import type { GetDepartmentDemandsCaregiverResponse } from '../models/GetDepartmentDemandsCaregiverResponse';
import type { GetDepartmentDemandsPatientResponse } from '../models/GetDepartmentDemandsPatientResponse';
import type { getPositionWithAttributionStatusResponseSchema } from '../models/getPositionWithAttributionStatusResponseSchema';
import type { getUserDemandsResponse } from '../models/getUserDemandsResponse';
import type { iammaApiGa83wPtQ59RIFp } from '../models/iammaApiGa83wPtQ59RIFp';
import type { iammaApiGaBPl5UsEqsXnw } from '../models/iammaApiGaBPl5UsEqsXnw';
import type { iammaApiGayGhXuGj6qHlN } from '../models/iammaApiGayGhXuGj6qHlN';
import type { iammaApiGazv3fmFCiPAIR } from '../models/iammaApiGazv3fmFCiPAIR';
import type { patientTokenResponse } from '../models/patientTokenResponse';
import type { updateSubCaregiverResponse } from '../models/updateSubCaregiverResponse';
import type { updateSubCaregiverSchema } from '../models/updateSubCaregiverSchema';
import type { upsertCategoryInput } from '../models/upsertCategoryInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param authorization
     * @param departmentId
     * @returns GetDepartmentDemandsPatientResponse 200 response
     * @throws ApiError
     */
    public static getDepartmentDemandsPatient(
        authorization: string,
        departmentId: string,
    ): CancelablePromise<GetDepartmentDemandsPatientResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requester/get-department-demand-patient/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterGetDepartmentDemandPatient(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/get-department-demand-patient/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param serviceId
     * @throws ApiError
     */
    public static getServiceInfos(
        serviceId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/get-service-infos/{serviceId}',
            path: {
                'serviceId': serviceId,
            },
        });
    }

    /**
     * @param serviceId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsOrganizationGetServiceInfos(
        serviceId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/organization/get-service-infos/{serviceId}',
            path: {
                'serviceId': serviceId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param requestBody
     * @returns CreateDemandResponse 200 response
     * @throws ApiError
     */
    public static createDemand(
        authorization: string,
        requestBody: CreateDemandSchema,
    ): CancelablePromise<CreateDemandResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/requester/create-demand',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterCreateDemand(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/create-demand',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param sub
     * @throws ApiError
     */
    public static deleteCaregiver(
        authorization: string,
        departmentId: string,
        sub: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/iam/delete-caregiver/{departmentId}/{sub}',
            path: {
                'departmentId': departmentId,
                'sub': sub,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @param sub
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamDeleteCaregiver(
        departmentId: string,
        sub: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/delete-caregiver/{departmentId}/{sub}',
            path: {
                'departmentId': departmentId,
                'sub': sub,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param departmentId
     * @throws ApiError
     */
    public static getDepartmentCaregivers(
        departmentId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/iam/get-department-caregivers/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamGetDepartmentCaregivers(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/get-department-caregivers/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param userId
     * @returns getUserDemandsResponse 200 response
     * @throws ApiError
     */
    public static getUserDemands(
        authorization: string,
        userId: string,
    ): CancelablePromise<getUserDemandsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requester/get-user-demand/{userId}',
            path: {
                'userId': userId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param userId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterGetUserDemand(
        userId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/get-user-demand/{userId}',
            path: {
                'userId': userId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param demandId
     * @throws ApiError
     */
    public static cancelDemand(
        authorization: string,
        departmentId: string,
        demandId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/requester/cancel-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @param demandId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterCancelDemand(
        departmentId: string,
        demandId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/cancel-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param demandId
     * @throws ApiError
     */
    public static getDemandById(
        authorization: string,
        departmentId: string,
        demandId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requester/get-demand-by-id/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @param demandId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterGetDemandById(
        departmentId: string,
        demandId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/get-demand-by-id/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @returns deleteDeviceTokenResponse 200 response
     * @throws ApiError
     */
    public static deleteDeviceToken(
        authorization: string,
    ): CancelablePromise<deleteDeviceTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/delete-device-token',
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsNotificationDeleteDeviceToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/notification/delete-device-token',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param userId
     * @param departmentId
     * @throws ApiError
     */
    public static getPatientStatus(
        userId: string,
        departmentId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/position/get-patient-status/{userId}/{departmentId}',
            path: {
                'userId': userId,
                'departmentId': departmentId,
            },
        });
    }

    /**
     * @param userId
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsPositionGetPatientStatus(
        userId: string,
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/position/get-patient-status/{userId}/{departmentId}',
            path: {
                'userId': userId,
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param sub
     * @param requestBody
     * @returns caregiverDeleteResponse 200 response
     * @throws ApiError
     */
    public static resetPassword(
        authorization: string,
        sub: string,
        requestBody: iammaApiGaBPl5UsEqsXnw,
    ): CancelablePromise<caregiverDeleteResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/iam/reset-password/{sub}',
            path: {
                'sub': sub,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sub
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamResetPassword(
        sub: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/reset-password/{sub}',
            path: {
                'sub': sub,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @returns getPositionWithAttributionStatusResponseSchema 200 response
     * @throws ApiError
     */
    public static getPositionWithAttributionStatus(
        authorization: string,
        departmentId: string,
    ): CancelablePromise<getPositionWithAttributionStatusResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/position/get-position-with-attribution-status/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsPositionGetPositionWithAttributionStatus(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/position/get-position-with-attribution-status/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param sub
     * @param requestBody
     * @returns updateSubCaregiverResponse 200 response
     * @throws ApiError
     */
    public static updateCaregiver(
        authorization: string,
        sub: string,
        requestBody: updateSubCaregiverSchema,
    ): CancelablePromise<updateSubCaregiverResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/iam/update-caregiver/sub/{sub}',
            path: {
                'sub': sub,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sub
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamUpdateCaregiverSub(
        sub: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/update-caregiver/sub/{sub}',
            path: {
                'sub': sub,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @returns GetDepartmentDemandsCaregiverResponse 200 response
     * @throws ApiError
     */
    public static getDepartmentDemandsCaregiver(
        authorization: string,
        departmentId: string,
    ): CancelablePromise<GetDepartmentDemandsCaregiverResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/requester/get-department-demand-caregiver/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterGetDepartmentDemandCaregiver(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/get-department-demand-caregiver/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param departmentId
     * @throws ApiError
     */
    public static getDepartmentCategoryTree(
        departmentId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/category/get-department-tree/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsCategoryGetDepartmentTree(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/category/get-department-tree/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param caregiverId
     * @param requestBody
     * @returns caregiverPinCheck 200 response
     * @throws ApiError
     */
    public static checkCaregiverPin(
        caregiverId: string,
        requestBody: iammaApiGazv3fmFCiPAIR,
    ): CancelablePromise<caregiverPinCheck> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/iam/check-caregiver-pin/{caregiverId}',
            path: {
                'caregiverId': caregiverId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param caregiverId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamCheckCaregiverPin(
        caregiverId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/check-caregiver-pin/{caregiverId}',
            path: {
                'caregiverId': caregiverId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param sub
     * @param requestBody
     * @returns caregiverDeleteResponse 200 response
     * @throws ApiError
     */
    public static resetPin(
        authorization: string,
        sub: string,
        requestBody: iammaApiGa83wPtQ59RIFp,
    ): CancelablePromise<caregiverDeleteResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/iam/reset-pin/{sub}',
            path: {
                'sub': sub,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sub
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamResetPin(
        sub: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/reset-pin/{sub}',
            path: {
                'sub': sub,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param caregiverId
     * @returns caregiverInfos 200 response
     * @throws ApiError
     */
    public static getCaregiverInfo(
        authorization: string,
        caregiverId: string,
    ): CancelablePromise<caregiverInfos> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/iam/get-caregiver-infos/{caregiverId}',
            path: {
                'caregiverId': caregiverId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param caregiverId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamGetCaregiverInfos(
        caregiverId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/get-caregiver-infos/{caregiverId}',
            path: {
                'caregiverId': caregiverId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param requestBody
     * @returns patientTokenResponse 200 response
     * @throws ApiError
     */
    public static getPatientToken(
        requestBody: iammaApiGayGhXuGj6qHlN,
    ): CancelablePromise<patientTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/iam/get-patient-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamGetPatientToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/get-patient-token',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param year
     * @param month
     * @param authorization
     * @param departmentId
     * @param day
     * @throws ApiError
     */
    public static getHistoryDemand(
        year: string,
        month: string,
        authorization: string,
        departmentId: string,
        day?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/history/get-history-by-department/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'year': year,
                'month': month,
                'day': day,
            },
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsHistoryGetHistoryByDepartment(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/history/get-history-by-department/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param requestBody
     * @returns addDeviceTokenResponse 200 response
     * @throws ApiError
     */
    public static addDeviceToken(
        authorization: string,
        requestBody: addDeviceTokenBody,
    ): CancelablePromise<addDeviceTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/add-device-token',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsNotificationAddDeviceToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/notification/add-device-token',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @returns any 200 response
     * @throws ApiError
     */
    public static getVersion(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param demandId
     * @throws ApiError
     */
    public static finishDemand(
        authorization: string,
        departmentId: string,
        demandId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/requester/finish-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @param demandId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterFinishDemand(
        departmentId: string,
        demandId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/finish-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param demandId
     * @throws ApiError
     */
    public static acceptDemand(
        authorization: string,
        departmentId: string,
        demandId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/requester/accept-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @param demandId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterAcceptDemand(
        departmentId: string,
        demandId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/accept-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param departmentId
     * @param requestBody
     * @throws ApiError
     */
    public static upsertCategoryTree(
        departmentId: string,
        requestBody: upsertCategoryInput,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/category/tree/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsCategoryTree(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/category/tree/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param demandId
     * @throws ApiError
     */
    public static refuseDemand(
        authorization: string,
        departmentId: string,
        demandId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/requester/refuse-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param departmentId
     * @param demandId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsRequesterRefuseDemand(
        departmentId: string,
        demandId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/requester/refuse-demand/{departmentId}/{demandId}',
            path: {
                'departmentId': departmentId,
                'demandId': demandId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param positionId
     * @throws ApiError
     */
    public static getServiceFromPosition(
        positionId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/get-service-from-position/{positionId}',
            path: {
                'positionId': positionId,
            },
        });
    }

    /**
     * @param positionId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsOrganizationGetServiceFromPosition(
        positionId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/organization/get-service-from-position/{positionId}',
            path: {
                'positionId': positionId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

    /**
     * @param authorization
     * @param departmentId
     * @param requestBody
     * @returns createCaregiverResponse 200 response
     * @throws ApiError
     */
    public static createCaregiver(
        authorization: string,
        departmentId: string,
        requestBody: createCaregiverSchema,
    ): CancelablePromise<createCaregiverResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/iam/create-caregiver/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param departmentId
     * @returns string 200 response
     * @throws ApiError
     */
    public static optionsIamCreateCaregiver(
        departmentId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/iam/create-caregiver/{departmentId}',
            path: {
                'departmentId': departmentId,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }

}
