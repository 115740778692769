import React from 'react';
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Paragraph, Title } from '../../../components/texts';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { PriorityEnum } from '../../../types/enums/PriorityEnum';
import { Colors } from '../../../styles/theme';
import { getPriority, getPriorityString } from '../../../utils/helpers/getPriority';
import { getStatus } from '../../../utils/helpers/getStatus';

interface GeneralDetailsInterface {
  category: any;
  parentCategory: any;
  request: any;
}

const GeneralDetails = ({ category, parentCategory, request }: GeneralDetailsInterface) => {
  const dateTakeAt = new Date(request.takeAt);

  const renderComment = (commentary: string | undefined) => {
    if (commentary) {
      return (
        <View>
          <ScrollView style={styles.commentaryContainer}>
            <Text style={styles.sectionTitle}>Commentaire patient</Text>
            <Paragraph grey>{commentary}</Paragraph>
          </ScrollView>
        </View>
      );
    }
  };

  const renderBodyPart = (bodyPart: any) => {
    if (bodyPart) {
      return (
        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Title h2>Partie du corps</Title>
          </View>
          <Title h3>{bodyPart.name}</Title>
        </View>
      );
    }
  };

  const renderTakeBy = () => {
    return (
      <>
        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Text style={styles.sectionTitle}>Par</Text>
          </View>
          <Title h3>{`${request.takeBy?.name} ${request.takeBy?.firstname}`}</Title>
        </View>

        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Text style={styles.sectionTitle}>Prise en charge à </Text>
          </View>
          <Title h3>
            {dateTakeAt.getHours()}h
            {dateTakeAt.getMinutes() < 10 ? `0${dateTakeAt.getMinutes()}` : dateTakeAt.getMinutes()}
          </Title>
        </View>
      </>
    );
  };

  return (
    <View>
      {renderComment(request.comment)}
      <View>
        <View style={styles.detailsContainer}>
          <View style={styles.detailsSubContainer}>
            <View style={styles.detailsTitleContainer}>
              <Text style={styles.sectionTitle}>Statut</Text>
            </View>
            <Title h3>{getStatus(request.status)}</Title>
          </View>
          <View style={styles.detailsSubContainer}>
            <View style={styles.detailsTitleContainer}>
              <Text style={styles.sectionTitle}>Priorité</Text>
            </View>
            <Title h3>{getPriority(getPriorityString(category.priority))}</Title>
          </View>
          {request.status === StatusEnum.PROCESSING ? renderTakeBy() : null}
        </View>
      </View>
    </View>
  );
};

export default GeneralDetails;

const styles = StyleSheet.create({
  commentaryContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 1,
    shadowRadius: 11.95,
    elevation: 5,
    padding: 15,
    minHeight: 80,
    marginVertical: 20,
  },
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 0.3,
    shadowRadius: 11.95,
    elevation: 5,
    display: 'flex',
    padding: 15,
    marginTop: 30,
  },
  detailsSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: Platform.OS === 'web' ? 0 : 20,
  },
  detailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  sectionTitle: {
    color: Colors.GREY,
    fontSize: 17,
    fontWeight: 'bold',
  },
});
