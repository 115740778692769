import React from 'react';
import { ActivityIndicator, View } from 'react-native';

export const Loader = () => {
  return (
    <View style={{ height: 300, display:"flex", justifyContent:"center" }}>
      <ActivityIndicator size="large" color="#7849c4" />
    </View>
  );
};
