import { StatusEnum } from '../../types/enums/StatusEnum';

export const getStatus = (priority: StatusEnum) => {
  switch (priority) {
    case StatusEnum.NEW:
      return 'Nouvelle';
    case StatusEnum.REBOOSTED:
    case StatusEnum.BOOSTED:
      return 'Relancée';
    case StatusEnum.PROCESSING:
      return 'En cours de traitement';
    case StatusEnum.CANCELLED:
      return 'Annulée';
    case StatusEnum.TERMINATED:
      return 'Terminée';
  }
};
