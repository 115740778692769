import React from 'react';


import {Title} from '../../components/texts';
import {AuthBottomContainer, Container} from '../../layouts/';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import {useUserContext} from '../../providers/UserProvider';
import {Avatar} from 'react-native-paper';
import {getUsernameOrPreferredUsername} from '../../utils/helpers/validations/getUsernameOrPreferredUsername';
import RequestsListsModule from '../../modules/dashboard/requests/RequestsLists.module';
import {Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Entypo} from '@expo/vector-icons';
import {ROUTES} from '../../router/Routes';
import { useQuery } from '@tanstack/react-query';
import { DefaultService } from '../../generated/api';
import { useAuthContext } from '../../providers/AuthProvider';
import { useDepartmentContext } from '../../providers/DepartmentProvider';

const DashboardWeb: React.FunctionComponent = () => {
    const {navigate} = useNavigation();

    const userContext = useUserContext();
    const authContext = useAuthContext();
    const departmentContext = useDepartmentContext();
    const departmentDemands = useQuery(
        ['departmentDemands', authContext.authState.token],
        async () => {
          if (!authContext.authState.token) return null;
          
          return DefaultService.getDepartmentDemandsCaregiver(
            `Bearer ${authContext.authState.token}`,
            userContext.userState.profile?.currentDepartment
          );
        },
        {
          refetchInterval: 3000,
          refetchOnWindowFocus: true,
        }
      );


    const renderRequestModule = () => {
        if (
           departmentDemands.data?.records.length === 0 
        ) {
            return <NoRequestIndicator/>;
        } else {
            return <RequestsListsModule fromLockScreen={true} demands={departmentDemands.data?.records}/>;
        }
    };

    const NoRequestIndicator = () => {
        return (
            <Container flexCol alignCenter justifyCenter marginVertical={50}>
                <Image source={require('../../assets/images/requests/noRequest.png')} style={{width: 70, height: 70}}/>
                <Title h1 center color={"#032E5C"}>
                    Vous êtes à jour
                </Title>
                <Title h3 center color={"gray"} mvXS>
                    Profitez de ce moment ☀️
                </Title>
            </Container>
        );
    };

    const today = new Date().toLocaleDateString(
        'fr-fr',
        {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'utc',
        },
    );

    const redirect = () => {
            navigate(ROUTES.PIN_SCREEN_WEB.name)
    }


    return (
        <Container primary flex={1} onClick={redirect}>
            <Container alignCenter primary height={150}>
                <Container alignCenter justifyStart primary flex={1} flexRow width={"100%"} customStyle={""}>
                    <Container alignCenter flex={1}>
                        <Container backgroundColor={'white'} flexRow alignCenter justifyBetween width={300} radius={20}
                                   paddingHorizontal={20} paddingVertical={10}>
                            <View>
                                <Avatar.Text
                                    size={70}
                                    label={getUsernameOrPreferredUsername(userContext.userState.profile)[0].toUpperCase()}
                                />
                            </View>
                            <Container flexRow alignCenter justifyBetween>
                                <View>
                                <Title h2 black mvXS>
                                    {departmentContext.departmentInfos?.name}
                                </Title>
                                <Title h3 mvXS>
                                    {getUsernameOrPreferredUsername(userContext.userState.profile)}
                                </Title>
                                </View>
                            </Container>
                        </Container>
                    </Container>
                    <Container flex={1} justifyCenter alignCenter>
                        <Text style={styles.title}>
                            {today}
                        </Text>
                    </Container>
                    <Container flex={1} justifyCenter alignCenter>
                        <TouchableOpacity onPress={() => navigate(ROUTES.PIN_SCREEN_WEB.name)} style={{
                            backgroundColor: '#E9126C',
                            width: 70,
                            height: 70,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Entypo name='lock' size={40} color='white'/>
                        </TouchableOpacity>
                    </Container>
                </Container>
            </Container>
            <AuthBottomContainer>
                {renderRequestModule()}
            </AuthBottomContainer>
        </Container>
    );
};
export {DashboardWeb};


const styles = StyleSheet.create({
    title: {
        color: 'white',
        fontSize: 35,
    },
});
