import React from 'react';
import { ROUTES } from './Routes';
import { enableScreens } from 'react-native-screens';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { PatientScreen } from '../screens/dashboard/PatientScreen';
import { AntDesign, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../styles/theme';
import { Platform } from 'react-native';
import { ServiceScreen } from '../screens/dashboard/ServiceScreen';
import { useDemandsContext } from '../providers/DemandsProvider';
import { StatusEnum } from '../types/enums/StatusEnum';
import { isSmallScreen } from '../utils/helpers/isSmallScreen';
import { RenderComponentIfEnabled } from '../utils/HOC/renderComponentIfEnabled';

enableScreens();
const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  const demandsContext = useDemandsContext();
  const notifNumber = demandsContext.demandsState && demandsContext.demandsState.filter(
    (request: any) => request.status !== StatusEnum.PROCESSING && request.status !== StatusEnum.CANCELLED
  ).length

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused }) => {
          if (route.name === ROUTES.HOME_SCREEN.name) {
            return focused ? (
              <FontAwesome name="user-circle" size={28} color={Colors.PRIMARY} style={{ marginTop: 12 }} />
            ) : (
              <FontAwesome name="user-circle" size={28} color="lightgray" style={{ marginTop: 12 }} />
            );
          } else if (route.name === ROUTES.SERVICE_SCREEN.name) {
            return focused ? (
              <AntDesign name="appstore1" size={28} color={Colors.PRIMARY} style={{ marginTop: 12 }} />
            ) : (
              <AntDesign name="appstore1" size={28} color="lightgray" style={{ marginTop: 12 }} />
            );
          } else if (route.name === ROUTES.CHAT_SCREEN.name) {
            return focused ? (
              <MaterialIcons name="message" size={28} color={Colors.PRIMARY} style={{ marginTop: 12 }} />
            ) : (
              <MaterialIcons name="message" size={28} color="lightgray" style={{ marginTop: 12 }} />
            );
          }
        },
      })}
      tabBarOptions={{
        activeTintColor: '#434343',
        inactiveTintColor: 'gray',
        labelStyle: { fontSize: 18, fontWeight: '400' },
        style: {
          height: Platform.OS === 'ios' && !isSmallScreen() ? 100 : 75,
          shadowColor: '#b1b1b1',
          shadowOffset: {
            width: 0,
            height: 9,
          },
          shadowOpacity: 0.55,
          shadowRadius: 11.95,
          elevation: 18,
        },
      }}
    >
      {RenderComponentIfEnabled(
        <Tab.Screen
          name={ROUTES.HOME_SCREEN.name}
          component={PatientScreen}
          options={{
            title: 'Patients',
            tabBarBadge: notifNumber > 0 ? notifNumber : undefined,
          }}
        />,
        //featuresContext.featuresState.communication
        true
      )}


      <Tab.Screen
        options={{
          title: 'Service',
        }}
        name={ROUTES.SERVICE_SCREEN.name}
        component={ServiceScreen}
      />
    </Tab.Navigator>
  );
};

export default TabNavigator;
